<template>
	<div>
		<h3>日历组件 需要在需求页面引入Calendar组件进行使用</h3>

		<h4>直接使用</h4>
		<div style="width: 1300px;background: #FFFFFF;border: 2px;">
			<Calendar :roomId="1"></Calendar>
		</div>
		<hr>

		<h4>配合el-dialog弹出使用</h4>
		<button @click="outVisible = true">弹出room日历</button>
		<el-dialog :close-on-click-modal="false" width="1300px" title='ROOM日历' center :visible.sync='outVisible' >
			<Calendar :roomId="3"></Calendar>
		</el-dialog>



		<button @click="outVisible1 = true">弹出个人日历</button>
		<el-dialog :close-on-click-modal="false" width="1300px" title='个人日历' center :visible.sync='outVisible1' >
			<Calendar></Calendar>
		</el-dialog>
	</div>
</template>

<script>
import Calendar from "@/components/common/calendar/Calendar.vue";

export default {
	name: "CalendarExample.vue",
	components: {Calendar},
	data(){
		return{
			outVisible:false,
			outVisible1:false
		}
	}
}
</script>

<style scoped>

</style>
